<template>
    <content-wrapper
        :title="$trans('Capacity')"
        fluid
    >
        <capacity-table
            :payload="{teacher: $auth.member.uuid}"
        />
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import CapacityTable from '@apps/school/components/Capacity/CapacityTable'
export default {
    name: 'Capacity',
    components: { CapacityTable, ContentWrapper }
}
</script>

<style lang=scss>

</style>
